import {useStaticQuery, graphql} from "gatsby";
import React from "react";
import {NavigationContext} from "../services/navigation";
import window from '../utils/window';
import {Helmet} from "react-helmet"

const IndexPage: React.FC<any> = () => {
  const {setLocation, setVisibleBackground, setBackgroundHeight} = React.useContext(NavigationContext);
  const ref = React.useRef();
  React.useEffect(() => {
    setLocation((window as any).location.pathname);
    setVisibleBackground(2);
    const pageHeight = (ref.current as any)?.clientHeight;
    setBackgroundHeight(pageHeight)
  }, []);
  const {bisbikis} = useStaticQuery(graphql`
    query {
      bisbikis: file(relativePath: {eq: "mesaia4.jpg"}) {
        publicURL
      }
    }
  `)
  return (
    <Helmet
      title={`Stavros Markonis`}
      meta={[
        {
          name: `description`,
          content: `Official website of film composer Stavros Markonis`
        },
        {
          name: 'keywords',
          content: 'Stavros Markonis, official website, film composer',
        },
        {
          property: `og:title`,
          content: `Stavros Markonis`,
        },
        {
          property: `og:description`,
          content: `Official website of film composer Stavros Markonis`
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `${process.env.GATSBY_CLIENT_ORIGIN}${bisbikis?.publicURL}`
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: `Stavros Markonis`,
        },
        {
          name: `twitter:description`,
          content: `Official website of film composer Stavros Markonis`
        },
      ]}
    />
  );
}

export default IndexPage;
